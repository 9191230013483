.errorHeaderContainer {
  margin-top: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .logo {
    display: flex;
    align-items: center;
    height: 40px;
  }
}
