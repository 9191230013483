.wrapper {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  justify-items: center;
}

.darkOverlay {
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
}

.darkOverlay.active {
  visibility: visible;
}

@media (max-width: 960px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 0fr;
  }

  .darkOverlay {
    background: rgba(0, 0, 0);
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .darkOverlay.active {
    opacity: 0.8;
  }
}

@media (max-width: 360px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
  }
}
