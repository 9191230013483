.gameWrapper {
  width: var(--all-game-basic-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
}

.hiddenGameWrapper {
  display: none;
}

.gameContainer {
  margin-top: 30px;
  min-height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.flipNotification {
  display: none;
}

@media screen and (max-width: 926px) and (orientation: landscape) {
  .gameWrapper {
    width: calc(var(--slot-landscape-height) * 1920 / 1080);
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .gameWrapper {
    width: calc(var(--slot-portrait-height) * 1080 / 1920);
  }
}

@media screen and (max-width: 320px) {
  .gameWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.gameWrapperMobile {
  height: 100%;
}

.hiddenGameWrapperMobile {
  display: none;
}

.gameContainerMobile {
  height: 100%;
}

.gameContainerMobile.notSupported {
  min-height: 85vh;
}

.flipNotificationMobile {
  display: none;
}

@media only screen and (max-width: 1092px) and (orientation: portrait) {
  .flipNotification {
    display: block;
  }

  .flipNotificationMobile {
    display: block;
  }
}

@media screen and (max-width: 320px) {
  .gameWrapperMobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}
