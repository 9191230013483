.searchIconWrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 7px 5px;
}

.searchIconElement {
  stroke: black;
}

.searchIconWrapper:hover {
  .searchIconElement {
    stroke: #FF8A00;
  }
}