.errorContainer {
    padding: 60px 0;
}

.errorWrapper {
    max-width: 620px;
    margin: 0 auto;

    display:flex;
    flex-direction: column;
    align-items: center;
    font-family: "Coolvetica", sans-serif;
    font-weight: 400;
}

.errorImage {
    margin: 0 auto;
}

.errorText {
    padding: 20px 15px 20px 15px;
    font-size: clamp(30px, 6vw, 60px);
    text-align: center;
}

@media(max-width: 1300px) {
    .errorImage img{
        max-width: 303px;
        max-height: 266px;
    }
}

@media(max-width: 550px) {
    .errorImage img{
        max-width: 221px;
        max-height: 185px;
    }
}

@media(max-height: 800px) {
    .errorImage img{
        max-width: 303px;
        max-height: 266px;
    }

    .errorContainer {
        padding: 30px 0;
    }

    .errorText {
        font-size: 30px;
    }
}

@media(max-height: 601px) {
    .errorImage img{
        max-width: 221px;
        max-height: 185px;
    }

    .errorContainer {
        padding: 30px 0;
    }

    .errorText {
        font-size: 30px;
    }
}

