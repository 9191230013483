.mainHeaderContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 93px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.searchOpened .mainHeaderTitleSlider {
    display: none;
}

.mainHeaderTitleSlider {
    width: 66%;
}

@media(max-width: 640px) {
    .searchOpened .mainHeaderTitleSlider {
        display: block;
    }

    .searchOpened .logo {
        display: none;
    }

    .logo {
        display: flex;
        align-items: center;
        height: 40px;
    }

    .mainHeaderTitleSlider {
        padding-top: 20px;
        order: 2;
        flex: 100%;
    }

    .mainHeaderContainer {
        height: 106px;
    }
}