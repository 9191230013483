.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c7c7c7;
  margin-top: 40px;
  height: 66px;
}

.text {
  margin-right: 12px;
  font-size: 15px;
  font-family: "Coolvetica", sans-serif;
  font-weight: 500;
  font-style: normal;
}