.backButton {
  font-family: "Coolvetica", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.arrow {
  width: 24px;
  height: 12px;
}

.cross {
  width: 35px;
  margin: 15px 15px 0 0;
}

.backButton p {
  font-size: 17px;
  padding-left: 10px;
  color: black;
}
