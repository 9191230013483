@font-face {
    font-family: 'Coolvetica';
    src: url("./Coolvetica/CoolveticaLt-Regular.ttf") format('ttf'),
        url("./Coolvetica/CoolveticaLt-Regular.woff") format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Coolvetica';
    src: url("./Coolvetica/CoolveticaLt-Italic.ttf") format('ttf'),
        url("./Coolvetica/CoolveticaLt-Italic.woff") format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Coolvetica';
    src: url("./Coolvetica/CoolveticaBk-Regular.ttf") format('ttf'),
        url("./Coolvetica/CoolveticaBk-Regular.woff") format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Coolvetica';
    src: url("./Coolvetica/CoolveticaRg-Regular.ttf") format('ttf'),
        url("./Coolvetica/CoolveticaRg-Regular.woff") format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Coolvetica';
    src: url("./Coolvetica/CoolveticaRg-Bold.ttf") format('ttf'),
        url("./Coolvetica/CoolveticaRg-Bold.woff") format('woff');
    font-weight: 700;
    font-style: normal;
}