.playButton {
  font-size: 1.12em;
  font-weight: 500;
  border: 1px solid white;
  border-radius: 39px;
  height: 2.88em;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.playButton.enabled:hover {
  cursor: pointer;
  color: black;
  background-color: #FFB800;
  border: 1px solid #FFB800;
}

.playButton.disabled {
  cursor: not-allowed;
  color: rgb(168, 168, 168);
  border: 1px solid rgb(168, 168, 168);
}

.resetLinkStyles {
  text-decoration: none;
  color: inherit;
}