.mobile {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  height: 80dvh;
}

.mobile.loaded {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
}
