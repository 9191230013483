.category_container {
    padding-top: clamp(0px, 7.5vw, 20px);
    position: relative;
}

.category_list_container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.category_list_container::-webkit-scrollbar {
    display: none; 
}