.gameIFrame {
  height: calc(var(--all-game-basic-width) * 1080 / 1920);
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 50px;
}

.gameIFrame.mobile {
  width: 100vw;
  height: 100%;
  border: none;
  border-radius: 0px;
}
