.wrapper {
  height: 100%;
  margin-bottom: 15px;
}

.largeLayout {
  grid-column: span 2;
  max-width: 925px;
  width: 100%;
}

.smallLayout {
  grid-column: span 1;
  max-width: 456px;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.newLabel {
  position: absolute;
  right: 10px;
  top: 10px;
}

.thumbnailCard {
  position: relative;
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 46px;
  overflow: hidden;
  min-height: 130px;
  box-shadow: 0px 29px 44px -15px rgba(107, 99, 129, 1);
}

.title {
  text-align: center;
  margin-top: 12px;
  font-family: "Coolvetica", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  border: 1px solid #494949;
  border-radius: 46px;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: white;
  padding: 23px;
  font-family: "Coolvetica", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: clamp(10px, 2.5vw + 1px, 16px);
  visibility: hidden;
  z-index: 1;
}

.overlayHead {
  font-family: "Coolvetica", sans-serif;
  font-weight: 500;
  display: flex;
  font-size: 1.07em;
  opacity: 0;
  transition: opacity 1.3s ease;
}

.overlayHeadIcon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 10px;
}

.closeButtonIcon {
  margin-left: auto;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.overlayGameTitle {
  font-weight: 400;
  font-size: 1.62em;
  opacity: 0;
  transition: opacity 2s ease, visibility 0.3s;
  margin-top: 0.3em;
}

.overlayGameDescription {
  font-weight: 400;
  font-size: 1em;
  margin-top: 0.7em;
  opacity: 0;
  transition: opacity 1.3s ease, visibility 0.3s;
}

.overlayButtons {
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 1.8s ease, visibility 0.3s 0.1s ease;
  margin-top: 5px;
}

.overlayButtons > * {
  flex: 1;
  margin-right: 0.5em;
}

.overlayButtons > *:last-child {
  margin-right: 0;
}

.showComponent,
.showComponent .overlayHead,
.showComponent .overlayGameTitle,
.showComponent .overlayGameDescription,
.showComponent .overlayButtons {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1920px) {
  .newLabel {
    width: 64px;
    height: 64px;
  }
}

@media (max-width: 1264px) {
  .overlayButtons {
    flex-wrap: wrap;
  }

  .smallLayout .overlayButtons > * {
    flex: initial;
    width: 100%;
    margin: 10px 0 0 0;
  }

  .newLabel {
    width: 58px;
    height: 58px;
  }
}

@media (max-width: 1264px) and (min-width: 961px) {
  .overlayHead {
    font-size: 0.97em;
  }

  .overlayGameTitle {
    font-size: 1.3em;
  }

  .overlayGameDescription {
    font-size: 0.85em;
    margin-top: 0.2em;
  }
}

@media (max-width: 960px) {
  .newLabel {
    width: 64px;
    height: 64px;
  }

  .imageWrapper {
    border-radius: 22px;
  }

  .overlay {
    border-radius: 22px;
  }

  .closeButtonIcon {
    width: 13px;
    height: 13px;
  }

  .largeLayout {
    width: clamp(280px, 160vh, 100%);
  }

  .smallLayout {
    width: clamp(150px, 80vh, 100%);
  }

  .thumbnailCard {
    position: static;
  }

  .overlay {
    top: auto;
    margin: 0 15px;
    width: calc(100% - 30px);
    min-height: auto;
    z-index: 12;
  }

  .overlayButtons {
    flex-wrap: wrap;
  }

  .overlayButtons > * {
    flex: initial;
    width: 100%;
    margin: 10px 0 0 0;
  }
}

@media (max-width: 480px) {
  .newLabel {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 360px) {
  .wrapper {
    margin-bottom: 10px;
  }

  .largeLayout {
    grid-column: span 1;
  }
}
