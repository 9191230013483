.gameHeaderContainer {
  max-width: 1860px;
  margin: 0 auto;
  margin-top: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.gameHeaderContainer.mobile {
  margin-top: 0;
  height: 50px;
  background-color: black;
  width: 100dvw;
  display: flex;
  justify-content: end;
}

.gameHeaderContainer.mobileNotSupported {
  min-height: 80vh;
}

@media (max-width: 640px) {
  .logo {
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.gameHeaderTitle {
  font-family: "Coolvetica", sans-serif;
  font-weight: 500;
  font-size: 36px;
}

@media (max-width: 690px) {
  .gameHeaderTitle {
    display: none;
  }
}
