.container {
  position: relative;
  display: block;
  min-width: 31px;
}

.container.isOpen {
  width: 50%;
}

.input {
  background: transparent;
  margin: 0;
  font-size: 17px;
  color: inherit;
  border: none;
  width: 100%;
  border-bottom: 2px solid #000;
  padding: 9px 40px 9px 4px;
  font-family: "Coolvetica", sans-serif;
  font-weight: 400;
}

.input::placeholder {
  color: #000;
}

.input:focus {
  outline: none;
}

@media (max-width: 640px) {
  .container.isOpen {
    width: 100%;
  }
}